@font-face {
  font-family: 'icomoon';
  src: url(../fonts/f53fba8098384bb57298549eca088456.eot);
  src: url(../fonts/f53fba8098384bb57298549eca088456.eot#iefix) format("embedded-opentype"), url(../fonts/ba5dacb825a6f0dc12765a8fb59f6baf.ttf) format("truetype"), url(../fonts/a364de96ca8459f15b68d9dbf52f1165.woff) format("woff"), url(../fonts/d8bb08cd812ea9df189b440539312bee.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
}
/* =======================================================
*
* 	Template Style 
*	Edit this section
*
* ======================================================= */
body {
  font-family: "Roboto", Arial, serif;
  line-height: 1.8;
  font-size: 16px;
  background: #fff;
  color: #4A494C;
  font-weight: 300;
  overflow-x: hidden;
}
body.fh5co-offcanvas {
  overflow: hidden;
}

a {
  color: #bfacc8;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
a:hover {
  text-decoration: none !important;
  color: #d1ac00 !important;
}
a:active, a:focus {
  outline: none;
}

p, ul, ol {
  margin-bottom: 1.5em;
  font-size: 15px;
  color: #4A494C;
  font-family: "Roboto", Arial, serif;
}

h1, h2, h3, h4, h5, h6 {
  color: #b7dfd1;
  font-family: "Roboto", Arial, serif;
  margin: 0 0 20px 0;
}

figure {
  margin-bottom: 2em;
}

::-webkit-selection {
  color: #fcfcfc;
  background: #bfacc8;
}

::-moz-selection {
  color: #fcfcfc;
  background: #bfacc8;
}

::selection {
  color: #fcfcfc;
  background: #bfacc8;
}

#fh5co-header {
  padding-top: 40px;
  margin-bottom: 30px;
}
#fh5co-header #fh5co-logo img {
  max-width: 100%;
}
#fh5co-header nav {
  padding-top: 20px;
}
#fh5co-header nav ul {
  padding: 0;
  margin: 0;
}
#fh5co-header nav ul li {
  padding: 0;
  margin: 0;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  list-style: none;
  font-size: 15px;
}
#fh5co-header nav ul li a {
  color: #999999 !important;
  padding: 10px;
}
@media screen and (max-width: 480px) {
  #fh5co-header nav ul li a {
    padding: 5px;
  }
}
#fh5co-header nav ul li a:hover, #fh5co-header nav ul li a:active, #fh5co-header nav ul li a:focus {
  color: #d1ac00 !important;
  text-decoration: none;
  outline: none;
}
#fh5co-header nav ul li.active a {
  text-decoration: line-through !important;
  color: black !important;
}

.fh5co-projects-feed, .footer-content {
  margin: 0 auto;
  max-width: 1400px;
}

.fh5co-projects-feed .fh5co-project {
  float: left;
  margin: 20px 20px 20px;
  width: 290px;
}
@media screen and (max-width: 480px) {
  .fh5co-projects-feed .fh5co-project {
    margin: 10px 10px 10px;
  }
}
.fh5co-projects-feed .fh5co-project > a {
  display: block;
  text-align: center;
}
.fh5co-projects-feed .fh5co-project > a img {
  margin-bottom: 30px;
}
.fh5co-projects-feed .fh5co-project > a h2 {
  font-size: 14px;
  color: #4a494c;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.fh5co-projects-feed .fh5co-project > a:hover h2, .fh5co-projects-feed .fh5co-project > a:active h2, .fh5co-projects-feed .fh5co-project > a:focus h2 {
  color: #4a494c;
}

.masonry,
.masonry .masonry-brick {
  -webkit-transition-duration: 0.7s;
  transition-duration: 0.7s;
}

.masonry {
  -webkit-transition-property: height, width;
  transition-property: height, width;
}

.masonry .masonry-brick {
  -webkit-transition-property: left, right, top;
  transition-property: left, right, top;
}

.page-content {
  margin: 0 auto;
  max-width: 620px;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
}
.page-content img {
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  .page-content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.page-content .mb0 {
  margin-bottom: 0;
}
.page-content .meta {
  margin-bottom: 20px;
}
.page-content .meta span {
  color: #bfbfbf;
}
.page-content .meta a {
  color: #bfbfbf;
}
.page-content .meta a:hover, .page-content .meta a:active, .page-content .meta a:focus {
  color: #4a494c !important;
}
@media screen and (max-width: 480px) {
  .page-content {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

#fh5co-footer {
  float: left;
  width: 100%;
  background: #ebebeb;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: #999999;
}
#fh5co-footer a {
  color: #bfbfbf;
}
#fh5co-footer a:hover, #fh5co-footer a:active, #fh5co-footer a:focus {
  color: #000 !important;
}
#fh5co-footer .social {
  margin-top: 20px;
}
#fh5co-footer .social a {
  padding: 10px;
  color: #999999 !important;
}
@media screen and (max-width: 480px) {
  #fh5co-footer .social a {
    padding: 5px;
  }
}
#fh5co-footer .social a:hover, #fh5co-footer .social a:active, #fh5co-footer .social a:focus {
  color: #4a494c !important;
}

.square {
  list-style: square;
}

.pt70 {
  padding-top: 70px;
}
@media screen and (max-width: 480px) {
  .pt70 {
    padding-top: 30px;
  }
}

.pb70 {
  padding-bottom: 70px;
}
@media screen and (max-width: 480px) {
  .pb70 {
    padding-bottom: 30px;
  }
}
